import * as React from 'react';
import { motion } from 'framer-motion';
import { Box } from '@chakra-ui/react';
import ProjectView from '../../../components/project/viewProject/viewProject';
import SEO from '../../../components/seo/seo';

const ViewProjectPage = (props: { projectId: string }): JSX.Element => {
  return (
    <Box minH="90.5vh" maxW="1600px">
      <SEO title="Home" />
      <motion.div
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -50 }}
        transition={{
          type: 'spring',
          mass: 0.35,
          stiffness: 75,
          duration: 0.3,
        }}
      >
        <ProjectView projectId={props.projectId} />
      </motion.div>
    </Box>
  );
};

export default ViewProjectPage;
