/* eslint-disable no-irregular-whitespace */
import * as React from 'react';
import {
  Box,
  Flex,
  Heading,
  Input,
  Skeleton,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useClipboard,
  Text,
  InputGroup,
  Button,
  InputRightElement,
} from '@chakra-ui/react';
import { API, Auth } from 'aws-amplify';
import { useSelector } from 'react-redux';
import { Prism } from '@mantine/prism';
import SEO from '../../seo/seo';

interface ProjectViewProps {
  projectId: string;
}

const ProjectView = ({ projectId }: ProjectViewProps): JSX.Element => {
  const { currentAccount } = useSelector((state: any) => state.account);
  const [loading, setLoading] = React.useState(true);
  const [project, setProject] = React.useState(null);
  const accountId = useClipboard(currentAccount?.accountId);
  const projectIdCopy = useClipboard(projectId);

  const getToken = async () => {
    const token = (await Auth.currentSession()).getAccessToken().getJwtToken();
    return token;
  };

  React.useEffect(() => {
    setLoading(true);
    if (projectId) {
      let tok = null;
      getToken().then((res) => {
        tok = res;
        API.get('openscreen', `projects/${projectId}`, {
          headers: {
            Authorization: `Bearer ${tok}`,
          },
        })
          .then((response) => {
            setProject(response.project);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      });
    }
  }, [projectId]);

  // function PasswordInput() {
  //   const [show, setShow] = React.useState(false);
  //   const handleClick = () => setShow(!show);

  //   return (
  //     // <Flex maxW="400px">
  //     <InputGroup size="md">
  //       <Input
  //         variant="filled"
  //         borderColor="gray.300"
  //         border="1px"
  //         width="100%"
  //         type={show ? 'text' : 'password'}
  //         disabled
  //         _disabled={{ textColor: 'black' }}
  //         defaultValue={key?.key}
  //       />
  //       <InputRightElement width="5rem">
  //         <Button h="1.75rem" size="sm" onClick={handleClick}>
  //           {show ? 'Hide' : 'Show'}
  //         </Button>
  //       </InputRightElement>
  //     </InputGroup>
  //     // </Flex>
  //   );
  // }

  const npmInstallSnippet = `  npm i @openscreen/sdk`;

  // const axiosInstall = `  npm install axios`;

  const nodeSDKCode = `
  const Openscreen = require("@openscreen/sdk");
  const os = new Openscreen().config({
    key: "**KEY**",
    secret: "**SECRET**",
  });

  const projectId = "${projectId}";

  const generateDynamicQR = async (event) => {
    try {
      const { format, intent, foreground, size, description } = JSON.parse(
        event.body
      );
      const res = await os
        .project(projectId)
        .assets()
        .create({ name: description, description });
      const { assetId } = res.asset;
  
      let newIntent = intent;
      if (intent.startsWith("https://")) {
        newIntent = intent.slice(8);
      }
      const token = await os
        .asset(assetId)
        .qrCodes()
        .create({
          intentType: "DYNAMIC_REDIRECT",
          intent: \`https://\${newIntent}\`
        });

      const { qrCodeId } = token;

      const qr = await os.qrCode(qrCodeId).get({
        format,
        dataUrl: true,
        darkColor: foreground.hex,
        lightColor: "#fff",
        size,
      });
      return qr;
    } catch (error) {
      console.log(error);
  };
  // <----- For more information, please check out or docs ----->`;

  // const restAPICode = `  import axios from "axios";

  // // Creates an asset
  // const newAsset = await axios.post(
  //   "openscreen.com/projects/${projectId}/assets", {
  //   {
  //     "name": "Card Name",
  //     "customAttributes": {
  //       "batchId": "38C",
  //     },
  //     // Create multiple tokens for the asset
  //     "tokens": [
  //       {
  //         "locatorKeyType": "SHORT_URL"
  //       },
  //     ]
  //   }
  // })

  // // newAsset will return with the tokens (short url for the qr code) for the asset
  // // <----- For more information, please check out or docs ----->
  // `;

  return (
    <Flex
      width={{ base: '85%', md: '70vw' }}
      bgColor="#fff"
      marginX={{ base: 'auto', md: '40px' }}
    >
      <Flex padding="2em" align="start" margin="0" maxW="1000px">
        <Box marginLeft="2rem">
          <SEO title={project?.name || 'Manage Project'} />
          <Skeleton isLoaded={!loading}>
            <Heading marginBottom="0.5rem">{project?.name}</Heading>
          </Skeleton>
          <Box marginLeft="1.25rem">
            <Skeleton
              flexDirection={{ base: 'column', md: 'row' }}
              isLoaded={!loading}
            >
              <Stack>
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  align="center"
                >
                  <Text minW={{ base: '0px', md: '9rem' }}>Account ID</Text>
                  <InputGroup>
                    <Input
                      variant="filled"
                      borderColor="gray.300"
                      border="1px"
                      readOnly
                      value={accountId.value}
                    />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" onClick={accountId.onCopy}>
                        {accountId.hasCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </Stack>
            </Skeleton>{' '}
            <Skeleton isLoaded={!loading}>
              <Stack>
                <Flex
                  flexDirection={{ base: 'column', md: 'row' }}
                  marginY="1.25rem"
                  align="center"
                >
                  <Text minW={{ base: '0px', md: '9rem' }}>Project ID</Text>
                  <InputGroup>
                    <Input
                      variant="filled"
                      borderColor="gray.300"
                      border="1px"
                      readOnly
                      value={projectIdCopy.value}
                    />
                    <InputRightElement width="4.5rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={projectIdCopy.onCopy}
                      >
                        {projectIdCopy.hasCopied ? 'Copied' : 'Copy'}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </Flex>
              </Stack>
            </Skeleton>{' '}
            <Skeleton
              display={{ base: 'none', md: 'initial' }}
              marginY="1.25rem"
              isLoaded={!loading}
            >
              <Text marginY="1.25rem">
                Use these quick start snippets to get get started with coding in
                this project the way you want to
              </Text>
            </Skeleton>
            <Skeleton
              display={{ base: 'none', md: 'initial' }}
              isLoaded={!loading}
            >
              <Tabs colorScheme="teal" variant="solid-rounded">
                <TabList>
                  <Tab
                    _selected={{
                      bgColor: '#0C0D21',
                      colorScheme: 'teal',
                      color: 'white',
                    }}
                  >
                    Node SDK
                  </Tab>
                  {/* <Tab
                    disabled
                    _selected={{
                      bgColor: '#0C0D21',
                      colorScheme: 'teal',
                      color: 'white',
                    }}
                  >
                    REST API
                  </Tab> */}
                </TabList>
                <TabPanels>
                  <TabPanel>
                    <Prism
                      style={{
                        marginBottom: '5px',
                      }}
                      themeOverride={{ colorScheme: 'dark' }}
                      language="javascript"
                    >
                      {npmInstallSnippet}
                    </Prism>
                    <Prism
                      themeOverride={{ colorScheme: 'dark' }}
                      language="javascript"
                    >
                      {nodeSDKCode}
                    </Prism>
                  </TabPanel>
                  {/* <TabPanel w="800px">
                    <Prism
                      style={{
                        marginBottom: '5px',
                      }}
                      themeOverride={{ colorScheme: 'dark' }}
                      language="javascript"
                    >
                      {axiosInstall}
                    </Prism>
                    <Prism
                      themeOverride={{ colorScheme: 'dark' }}
                      language="tsx"
                    >
                      {restAPICode}
                    </Prism>
                  </TabPanel> */}
                </TabPanels>
              </Tabs>
            </Skeleton>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default ProjectView;
